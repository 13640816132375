exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kodi-functions-jsx": () => import("./../../../src/pages/kodi/functions.jsx" /* webpackChunkName: "component---src-pages-kodi-functions-jsx" */),
  "component---src-pages-kodi-instalation-jsx": () => import("./../../../src/pages/kodi/instalation.jsx" /* webpackChunkName: "component---src-pages-kodi-instalation-jsx" */),
  "component---src-pages-kodi-operators-jsx": () => import("./../../../src/pages/kodi/operators.jsx" /* webpackChunkName: "component---src-pages-kodi-operators-jsx" */),
  "component---src-pages-kodi-statements-jsx": () => import("./../../../src/pages/kodi/statements.jsx" /* webpackChunkName: "component---src-pages-kodi-statements-jsx" */),
  "component---src-pages-kodi-utilities-jsx": () => import("./../../../src/pages/kodi/utilities.jsx" /* webpackChunkName: "component---src-pages-kodi-utilities-jsx" */),
  "component---src-pages-kodi-variables-jsx": () => import("./../../../src/pages/kodi/variables.jsx" /* webpackChunkName: "component---src-pages-kodi-variables-jsx" */),
  "component---src-pages-nembohasa-js": () => import("./../../../src/pages/nembohasa.js" /* webpackChunkName: "component---src-pages-nembohasa-js" */)
}

